import React from 'react';

import DefaultMaxHeaderItem from '@components/pages/max/MaxHeader/MaxHeaderItem';
import type { MaxHeaderItemProps } from '@components/pages/max/MaxHeader/MaxHeaderItem';

import styles from './MaxHeaderItem.module.css';

const MaxHeaderItem = (props: MaxHeaderItemProps) => {
    return (
        <DefaultMaxHeaderItem
            {...props}
            themeStyles={styles}
        />
    );
};

export default MaxHeaderItem;
