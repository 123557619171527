import React from 'react';

import type { MaxHeaderButtonProps } from '@components/pages/max/MaxHeader/MaxHeaderButton';
import LegacyMaxHeaderButton from '@components/pages/max/MaxHeader/MaxHeaderButton';

import styles from './MaxHeaderButton.module.css';

const MaxHeaderButton = (props: MaxHeaderButtonProps) => {
    return (
        <LegacyMaxHeaderButton
            {...props}
            themeStyles={styles}
        />
    );
};

export default MaxHeaderButton;
