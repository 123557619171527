import { useRouter } from 'next/router';

import { useAppContext } from '@context/AppContext';

import useTranslation from '@hooks/useTranslation';
import { multisiteRouteLegalPageMaxAntispamPolicy, multisiteRouteLegalPageMaxTermsOfService, multisiteRouteLegalPagePrivacyMaxPrivacyPolicy, multisiteRouteLegalPagePrivacyUsMaxPrivacyPolicyUs } from '@static_components/customLinks';
import {
    multisiteRouteLegalPageMaxLegalPrivacyPolicyBr,
    multisiteRouteLegalPageMaxPolitykaAntyspamowa,
    multisiteRouteLegalPageMaxPolitykaPrywatnosci,
    multisiteRouteLegalPageMaxRegulaminUslugi,
    multisiteRouteLegalPagePrivacyRu,
} from '@static_components/multisiteLinks';
import clsx from 'clsx';

import styles from './FooterLegal.module.css';

interface FooterLegalProps {
    darkMode?: boolean;
}

const FooterLegal = ({ darkMode = false }: FooterLegalProps) => {
    const { t } = useTranslation('base/_blocks/footer');
    const { geo } = useAppContext();
    const { locale } = useRouter();
    const geoIso = geo?.country_iso_code ?? '';
    return (
        <div className={clsx(styles['mfooter-legal'], darkMode ? styles.darkMode : '')}>
            <ul>
                <li>
                    {['pl'].includes(locale ?? '') ? (
                        <a href={multisiteRouteLegalPageMaxPolitykaPrywatnosci(locale)}>{t('max_menu_privacy_policy')}</a>
                    ) : ['ru'].includes(locale ?? '') ? (
                        <a href={multisiteRouteLegalPagePrivacyRu(locale)}>{t('max_menu_privacy_policy')}</a>
                    ) : ['en'].includes(locale ?? '') && ['US', 'CA'].includes(geoIso) ? (
                        <a href={multisiteRouteLegalPagePrivacyUsMaxPrivacyPolicyUs(locale)}>{t('max_menu_privacy_policy')}</a>
                    ) : ['BR'].includes(geoIso) ? (
                        <a href={multisiteRouteLegalPageMaxLegalPrivacyPolicyBr(locale)}>{t('max_menu_privacy_policy')}</a>
                    ) : (
                        <a href={multisiteRouteLegalPagePrivacyMaxPrivacyPolicy(locale)}>{t('max_menu_privacy_policy')}</a>
                    )}
                </li>
                <li>
                    {['BR'].includes(geoIso) ? (
                        <a href="https://www.getresponse.com/pt/legal/max-anti-spam-policy-brazil-ltda">{t('max_menu_spam_policy')}</a>
                    ) : ['pl'].includes(locale ?? '') ? (
                        <a href={multisiteRouteLegalPageMaxPolitykaAntyspamowa(locale)}>{t('max_menu_spam_policy')}</a>
                    ) : ['ru'].includes(locale ?? '') ? (
                        <a href="https://multimedia.getresponse.com/getresponse-hUN6z/documents/4a579e5f-bad0-4225-9668-bd1b792b98d7.pdf">{t('max_menu_spam_policy')}</a>
                    ) : (
                        <a href={multisiteRouteLegalPageMaxAntispamPolicy(locale)}>{t('max_menu_spam_policy')}</a>
                    )}
                </li>
                <li>
                    {['BR'].includes(geoIso) ? (
                        <a href="https://www.getresponse.com/pt/legal/max-terms-of-services-getresponse-ltda">{t('max_menu_service_terms')}</a>
                    ) : ['pl'].includes(locale ?? '') ? (
                        <a href={multisiteRouteLegalPageMaxRegulaminUslugi(locale)}>{t('max_menu_service_terms')}</a>
                    ) : (
                        <a href={multisiteRouteLegalPageMaxTermsOfService(locale)}>{t('max_menu_service_terms')}</a>
                    )}
                </li>
                {['de'].includes(locale ?? '') && (
                    <li>
                        <a href="https://www.getresponse.com/de/impressum">{t('max_menu_impressum')}</a>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default FooterLegal;
