import React from 'react';

import type { MaxHeaderItemProps } from '@components/pages/max/MaxHeader/MaxHeaderItem';
import LegacyMaxHeaderItem from '@components/pages/max/MaxHeader/MaxHeaderItem';

import styles from './MaxHeaderItem.module.css';

const MaxHeaderItem = (props: MaxHeaderItemProps) => {
    return (
        <LegacyMaxHeaderItem
            {...props}
            themeStyles={styles}
        />
    );
};

export default MaxHeaderItem;
